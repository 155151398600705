<script>
  export default {
    name: 'EngagementBuilderUserProfile.vue',
    props: {
      user: {
        type: Object,
        required: true,
      },
      hasNewPosts: {
        type: Boolean,
        default: false,
      },
      isSelected: {
        type: Boolean,
        default: false,
      },
      isMobileView: {
        type: Boolean,
        default: false,
      },
      isMobileEngagementSettings: {
        type: Boolean,
        default: false,
      },
      isSearchTermFilterActive: {
        type: Boolean,
        default: false,
      },
      isUserFilterActive: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isHoveringOnProfile: false,
      };
    },
    computed: {
      shouldHighlightUser() {
        return (
          !(this.isUserFilterActive || this.isSearchTermFilterActive) ||
          (this.isUserFilterActive && this.isSelected)
        );
      },
    },
  };
</script>
<template>
  <div v-if="isMobileView">
    <div class="flex w-20 flex-col items-center" @click="$emit('filter-by-user', user.twitterId)">
      <div class="relative h-16 w-16 flex-shrink-0">
        <img
          v-if="user.profileImageUrl"
          class="h-full w-full rounded-full object-cover"
          :class="{
            'border-2 border-ds-button-icon': isSelected,
            'opacity-50': !shouldHighlightUser,
          }"
          :src="user.profileImageUrl"
          :alt="user.name"
        />
        <img
          v-else
          class="h-full w-full rounded-full object-cover"
          :class="{
            'border-2 border-ds-button-icon': isSelected,
            'opacity-50': !shouldHighlightUser,
          }"
          src="/img/twittter_profile_photo_placeholder.png"
          :alt="user.username"
        />
      </div>
      <p
        class="truncate text-ellipsis max-w-full text-xs"
        :class="{
          'text-ds-text-primary': isSelected,
          'text-ds-text-secondary-tertiary': !isSelected,
        }"
      >
        @{{ user.username }}
      </p>
    </div>
  </div>
  <div
    v-else-if="isMobileEngagementSettings"
    class="flex w-full items-center justify-between gap-x-4"
  >
    <div class="flex min-w-0 items-center gap-x-2">
      <div class="relative h-13 w-13 flex-shrink-0">
        <div class="relative h-12 w-12" data-cy="search-user-item-image">
          <img
            v-if="user.profileImageUrl"
            class="h-full w-full rounded-full object-cover"
            :src="user.profileImageUrl"
            :alt="user.name"
          />
          <img
            v-else
            class="h-full w-full rounded-full object-cover"
            src="/img/twittter_profile_photo_placeholder.png"
            :alt="user.username"
          />
        </div>
      </div>
      <div class="pointer-events-none min-w-0 cursor-pointer lg:pointer-events-auto">
        <p class="avatar-name text-ds-text-primary">
          {{ user.name }}
        </p>
        <p class="avatar-username">@{{ user.username }}</p>
      </div>
    </div>
    <div class="flex-shrink-0 lg:hidden">
      <new-button size="sm" type="secondary" @click="$emit('remove-user')">
        <inline-svg
          src="/img/icons/new/user.svg"
          class="mr-1 h-5 w-5 stroke-ds-button-secondary-label"
        />
        Remove
      </new-button>
    </div>
  </div>
  <div v-else class="flex w-full items-center justify-between gap-x-4">
    <div
      class="flex min-w-0 cursor-pointer items-center gap-x-2"
      @click.stop="$emit('filter-by-user', user.twitterId)"
    >
      <div
        class="relative h-13 w-13 flex-shrink-0"
        @mouseenter="isHoveringOnProfile = true"
        @mouseleave="isHoveringOnProfile = false"
      >
        <div
          v-if="isHoveringOnProfile"
          class="absolute -right-1 top-0 z-10 h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-ds-system-error-background lg:flex"
          @click.stop="$emit('remove-user')"
          data-cy="search-user-item-delete"
        >
          <inline-svg
            src="/img/icons/new/dash-icon.svg"
            class="h-3 w-3 stroke-ds-system-error-text"
          />
        </div>
        <div class="relative h-12 w-12" data-cy="search-user-item-image">
          <img
            v-if="user.profileImageUrl"
            class="h-full w-full rounded-full object-cover"
            :class="{
              'border-2 border-ds-button-icon': isSelected,
              'opacity-50': !shouldHighlightUser,
            }"
            :src="user.profileImageUrl"
            :alt="user.name"
          />
          <img
            v-else
            class="h-full w-full rounded-full object-cover"
            :class="{
              'border-2 border-ds-button-icon': isSelected,
              'opacity-50': !shouldHighlightUser,
            }"
            src="/img/twittter_profile_photo_placeholder.png"
            :alt="user.username"
          />
          <div
            v-if="false"
            class="absolute -right-1 top-1 h-3 w-3 rounded-full border-2 border-white bg-ds-button-primary-background"
          />
        </div>
      </div>
      <div class="pointer-events-none min-w-0 cursor-pointer lg:pointer-events-auto">
        <p
          class="avatar-name"
          :class="shouldHighlightUser ? 'text-ds-text-primary' : 'text-ds-text-secondary-tertiary'"
        >
          {{ user.name }}
        </p>
        <p class="avatar-username">@{{ user.username }}</p>
      </div>
    </div>
  </div>
</template>
